import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ToolsLayout from "../../../components/toolsLayout";
import SEO from "../../../components/seo";
import ToolSuggestionResultForm from "../../../components/suggest/toolSuggestionResultForm.js";
import SuggestionIllustration from "../../../images/suggestion-illustration.svg";

const ToolsSuggestResultPage = (props) =>
{
  const { site } = useStaticQuery(
    graphql`
      query
      {
        site
        {
          siteMetadata
          {
            tools
            {
              id
              name
              description
              link
            }
          }
        }
      }
    `
  );

  const tool = site.siteMetadata.tools.filter(t => t.id === 999)[0];

  return (
    <ToolsLayout
      show = { true }
      name = { tool.name }
      description = { tool.description }
      icon = { SuggestionIllustration }
      goBackDestination = "/tools"
      goBackText = "← Back to Tools Menu"
    >
      <SEO
        keywords = { [`LawChest`, `tool`, `suggest`, `suggestion`] }
        title = "Suggest a Tool"
      />

      <section
        id = "utilitySection"
        className = "text-center"
      >
        <ToolSuggestionResultForm />
      </section>
    </ToolsLayout>
  );
}

export default ToolsSuggestResultPage;
