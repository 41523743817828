import React from "react";
import { Link } from "gatsby";

const ToolSuggestionResultForm = (props) =>
{
  return (
    <div>
      <div
        className = "p-2 bg-gray-300"
      >
        <p>
          Thank you for suggesting a new Tool for the LawChest.
        </p>
        <div
          className = "my-6"
        >
          <Link
            to = { `/tools/suggest` }
            className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold m-3 px-4 py-2 rounded text-sm text-white"
          >
            Make Another Suggestion
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ToolSuggestionResultForm;